<script setup lang="ts">
import AppLoadingIndicator from '~/components/AppLoadingIndicator.vue'
import { onBeforeMount } from 'vue'
import { useSession } from '~/composables/useSession'
import { resetUserIdentity, setUserIdentity } from '~/utilities/auth'

onBeforeMount(() => {
  // at this point session is loaded, and we can set the user identity
  const userSession = useSession()
  if (userSession.data.value) {
    setUserIdentity(userSession.data.value?.email)
  } else {
    resetUserIdentity()
  }
})
</script>

<template>
  <div id="root">
    <AppLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
