
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91patientId_93z2AKoJcE6uMeta } from "/code/pages/audit-summary/[patientId].vue?macro=true";
import { default as _91patientId_93qAinKLrMuPMeta } from "/code/pages/credo-encounter-data/[patientId].vue?macro=true";
import { default as forgot_45passwordPdMPyiFOKaMeta } from "/code/pages/forgot-password.vue?macro=true";
import { default as indexKRDw4UYEiPMeta } from "/code/pages/index.vue?macro=true";
import { default as loginT5FXYAtKoAMeta } from "/code/pages/login.vue?macro=true";
import { default as _91patientId_93sYGaqwhwCZMeta } from "/code/pages/no-records/[patientId].vue?macro=true";
import { default as chartsWFeodjTxrnMeta } from "/code/pages/patient/[patientId]/index/charts.vue?macro=true";
import { default as labsant7zax2gxMeta } from "/code/pages/patient/[patientId]/index/labs.vue?macro=true";
import { default as medicationsVQZwepUOYOMeta } from "/code/pages/patient/[patientId]/index/medications.vue?macro=true";
import { default as vitalsHxYOgyfy9PMeta } from "/code/pages/patient/[patientId]/index/vitals.vue?macro=true";
import { default as charts7hpz9eP7kqMeta } from "/code/pages/patient/[patientId]/patient/charts.vue?macro=true";
import { default as labs4qE0U1KsYGMeta } from "/code/pages/patient/[patientId]/patient/labs.vue?macro=true";
import { default as medicationsasEJ4WM2bKMeta } from "/code/pages/patient/[patientId]/patient/medications.vue?macro=true";
import { default as vitalsxocHdhVeNNMeta } from "/code/pages/patient/[patientId]/patient/vitals.vue?macro=true";
import { default as chartsBSjQiFPiRmMeta } from "/code/pages/patients/[patientId]/index/charts.vue?macro=true";
import { default as labsobs796PuXKMeta } from "/code/pages/patients/[patientId]/index/labs.vue?macro=true";
import { default as medicationsHrwHoN4oOKMeta } from "/code/pages/patients/[patientId]/index/medications.vue?macro=true";
import { default as vitalsoSrqy7HnMkMeta } from "/code/pages/patients/[patientId]/index/vitals.vue?macro=true";
import { default as indexVitXxjEZz7Meta } from "/code/pages/patients/[patientId]/index.vue?macro=true";
import { default as _91fileId_93Z0nbFekCOeMeta } from "/code/pages/patients/[patientId]/pdf-viewer/[fileId].vue?macro=true";
import { default as request_45patientZEafXysGEMMeta } from "/code/pages/request-patient.vue?macro=true";
import { default as reset_45password85vHJgHLedMeta } from "/code/pages/reset-password.vue?macro=true";
import { default as signup8HRXv4VtmlMeta } from "/code/pages/signup.vue?macro=true";
import { default as callback84tJJ8JmoKMeta } from "/code/pages/smart/callback.vue?macro=true";
import { default as launch6YnlmJBIEEMeta } from "/code/pages/smart/launch.vue?macro=true";
import { default as test4EqADdRcYgMeta } from "/code/pages/test.vue?macro=true";
export default [
  {
    name: "audit-summary-patientId",
    path: "/audit-summary/:patientId()",
    meta: _91patientId_93z2AKoJcE6uMeta || {},
    component: () => import("/code/pages/audit-summary/[patientId].vue")
  },
  {
    name: "credo-encounter-data-patientId",
    path: "/credo-encounter-data/:patientId()",
    meta: _91patientId_93qAinKLrMuPMeta || {},
    component: () => import("/code/pages/credo-encounter-data/[patientId].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordPdMPyiFOKaMeta || {},
    component: () => import("/code/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/code/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginT5FXYAtKoAMeta || {},
    component: () => import("/code/pages/login.vue")
  },
  {
    name: "no-records-patientId",
    path: "/no-records/:patientId()",
    meta: _91patientId_93sYGaqwhwCZMeta || {},
    component: () => import("/code/pages/no-records/[patientId].vue")
  },
  {
    name: "patient-patientId-index-charts",
    path: "/patient/:patientId()/charts",
    component: () => import("/code/pages/patient/[patientId]/index/charts.vue")
  },
  {
    name: "patient-patientId-index-labs",
    path: "/patient/:patientId()/labs",
    component: () => import("/code/pages/patient/[patientId]/index/labs.vue")
  },
  {
    name: "patient-patientId-index-medications",
    path: "/patient/:patientId()/medications",
    component: () => import("/code/pages/patient/[patientId]/index/medications.vue")
  },
  {
    name: "patient-patientId-index-vitals",
    path: "/patient/:patientId()/vitals",
    component: () => import("/code/pages/patient/[patientId]/index/vitals.vue")
  },
  {
    name: "patient-patientId-patient-charts",
    path: "/patient/:patientId()/patient/charts",
    component: () => import("/code/pages/patient/[patientId]/patient/charts.vue")
  },
  {
    name: "patient-patientId-patient-labs",
    path: "/patient/:patientId()/patient/labs",
    component: () => import("/code/pages/patient/[patientId]/patient/labs.vue")
  },
  {
    name: "patient-patientId-patient-medications",
    path: "/patient/:patientId()/patient/medications",
    component: () => import("/code/pages/patient/[patientId]/patient/medications.vue")
  },
  {
    name: "patient-patientId-patient-vitals",
    path: "/patient/:patientId()/patient/vitals",
    component: () => import("/code/pages/patient/[patientId]/patient/vitals.vue")
  },
  {
    name: "patients-patientId",
    path: "/patients/:patientId()",
    meta: indexVitXxjEZz7Meta || {},
    component: () => import("/code/pages/patients/[patientId]/index.vue"),
    children: [
  {
    name: "patients-patientId-index-charts",
    path: "charts",
    meta: chartsBSjQiFPiRmMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index/charts.vue")
  },
  {
    name: "patients-patientId-index-labs",
    path: "labs",
    meta: labsobs796PuXKMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index/labs.vue")
  },
  {
    name: "patients-patientId-index-medications",
    path: "medications",
    meta: medicationsHrwHoN4oOKMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index/medications.vue")
  },
  {
    name: "patients-patientId-index-vitals",
    path: "vitals",
    meta: vitalsoSrqy7HnMkMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index/vitals.vue")
  }
]
  },
  {
    name: "patients-patientId-pdf-viewer-fileId",
    path: "/patients/:patientId()/pdf-viewer/:fileId()",
    meta: _91fileId_93Z0nbFekCOeMeta || {},
    component: () => import("/code/pages/patients/[patientId]/pdf-viewer/[fileId].vue")
  },
  {
    name: "request-patient",
    path: "/request-patient",
    component: () => import("/code/pages/request-patient.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password85vHJgHLedMeta || {},
    component: () => import("/code/pages/reset-password.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup8HRXv4VtmlMeta || {},
    component: () => import("/code/pages/signup.vue")
  },
  {
    name: "smart-callback",
    path: "/smart/callback",
    meta: callback84tJJ8JmoKMeta || {},
    component: () => import("/code/pages/smart/callback.vue")
  },
  {
    name: "smart-launch",
    path: "/smart/launch",
    meta: launch6YnlmJBIEEMeta || {},
    component: () => import("/code/pages/smart/launch.vue")
  },
  {
    name: "test",
    path: "/test",
    meta: test4EqADdRcYgMeta || {},
    component: () => import("/code/pages/test.vue")
  }
]